import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField } from '@mui/material';

function CreateDialog({ open, onClose, onRegister }) {
  const [name, setName] = useState('');

  const handleRegister = () => {
    onRegister(name);
    setName('');
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
      <Typography gutterBottom className="titleBanner4" sx={{padding: "18px",  textAlign: "center"}}>
          Completa tus nombres
          </Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          type="text"
          fullWidth
          value={name}
          autoFocus={true}
          onChange={(e) => setName(e.target.value)}
          sx={{
            '.MuiInputBase-input': { fontSize: '2.55rem' },
          }}
        />
      </DialogContent>
      <DialogActions>
        <div style={{display:"flex", justifyContent:"Center", width:"100%", paddingBottom:"20px"}}>
        <Button onClick={handleRegister} className='submitButton' sx={{color: "#FFFF", height:"60px !important", fontSize:"25px !important"}} >
          Registrar
        </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default CreateDialog;
