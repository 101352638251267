import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { LinearProgress } from '@mui/material';
import Lottie from 'lottie-react';
import successAnimationData from './success.json';

function SuccessDialog({ open, onClose, message }) {
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    let timer;
    if (progress > 0) {
      timer = setInterval(() => {
        setProgress(prevProgress => prevProgress - (100 / 200)); // Reducir el progreso en 1/6 cada segundo
      }, 100);
    }

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Lottie animationData={successAnimationData} autoplay={true} loop={false} speed={0.5}  style={{ width: '100px', height: '100px' }}  />
        <Typography className="titleBanner4" align="center" gutterBottom>
          {message}
        </Typography>
        <LinearProgress 
        variant="determinate" 
        value={progress}            
        sx={{
            height: 10,
            borderRadius: 5,
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#004178',
            },
          }} />
      </DialogContent>
    </Dialog>
  );
}

export default SuccessDialog;
