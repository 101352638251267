import React, { useState, useEffect } from 'react';
import './style.css';
import { socket } from '../../config/socket';

const Tvs = () => {
  const [nombre, setNombre] = useState('');
  const [selectedModule, setSelectedModule] = useState(null);
  const [id, setId] = useState('');
  const [cantEspera, setcantEspera] = useState(0);

  const handleNextClick = async () => {
    if (!selectedModule) {
      alert('En que módulo se encuentra?')
      return;
    }
    try {
      // Llamada GET para obtener la cola más antigua
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/colas/pending`);
      const data = await response.json();
      const oldestQueue = data[0];
      setNombre(oldestQueue.name);
      setId(oldestQueue._id);

      // Llamada PUT para actualizar el estado de la cola
      const putResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/colas/${oldestQueue._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status: 'attending',
          module: selectedModule,
          startTime: new Date().toISOString(),
        }),
      });

      if (putResponse.ok) {
        console.log('Cola actualizada exitosamente');
      } else {
        console.error('Error al actualizar la cola');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleModuleChange = (event) => {
    setSelectedModule(event.target.value);
  };
  useEffect(() => {
    fetchPending()
    socket.on('pendingUpdate', fetchPending);
    socket.on('attendingUpdate', fetchPending);
  }, [])

  const fetchPending = async () => {
    try {
      const response = await fetch('https://tvs-api.chatup.pe/api/colas/pending');
      if (!response.ok) {
        console.log('errpr de buscar pendientes')
      }
      const data = await response.json();
      if (data) {
        setcantEspera(data.length)
      }
    } catch (error) {
      console.error('Error fetching pending queue:', error);
    }
  };

  return (
    <div className="container"> {/* Aplicar clase 'container' */}
      <div className="title"> {/* Aplicar clase 'title' */}
        {nombre}
      </div>
      <select className="module-selector" value={selectedModule} onChange={handleModuleChange}> {/* Aplicar clase 'module-selector' */}
        <option value="null">Mi modulo es..</option>
        <option value="Módulo 1">Módulo 1</option>
        <option value="Módulo 2">Módulo 2</option>
        <option value="Módulo 3">Módulo 3</option>
        <option value="Módulo 4">Módulo 4</option>
        <option value="Módulo 5">Módulo 5</option>
      </select>
      <div className="buttons-container"> {/* Aplicar clase 'buttons-container' */}
        <button className="next-button" onClick={handleNextClick}>Siguiente ({cantEspera} en cola)</button> {/* Aplicar clase 'next-button' */}
      </div>
    </div>
  );
};

export default Tvs;
