import React, { useState } from 'react';
import { Typography, Button, TextField, Container } from '@mui/material';
import './register.css';
import DisabilityDialog from './DisabilityDialog';
import SuccessDialog from './SuccessDialog';
import dniImage from './dni.png';
import logo from './logo.png';
import iconAdmision from './iconAdmision.json';
import Lottie from 'lottie-react';
import CreateDialog from './CreateDialog';

function Register(props) {
  const [dni, setDNI] = useState('');
  const [data, setData] = useState(null);
  const [message, setMessage] = useState('');
  const [showLayoutHome, setShowLayoutHome] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [openDisabilityDialog, setOpenDisabilityDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [hasDisability, setHasDisability] = useState(false);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState("")


  let localhasDisability = false
  const handleSACARCITA = () => {
    setShowLayoutHome(true);
    setDNI('')
  };

  const handleRegister = () => {
    if (dni.length > 7) {
      setOpenDisabilityDialog(true); // Mostrar el DisabilityDialog
    }
  };

  const postDataToServer = (name) => {
    // Obtener la fecha actual
    const currentDate = new Date().toISOString();

    // Datos a enviar en la solicitud POST
    const postData = {
      name: name,
      isPreferred: localhasDisability,
      registrationDate: currentDate,
      status: "pending"
    };

    // Configuración de la solicitud POST
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    };

    // Realizar la solicitud POST
    fetch(`${process.env.REACT_APP_BACKEND_URL}api/colas`, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setShowLayoutHome(false);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const fetchRegister = () => {
    setData(null)
    props.handleLoading(true)
    if (dni.length > 7) {
      fetch(`${process.env.REACT_APP_BACKEND_URL}api/reniec?dni=${dni}`)
        .then(response => response.json())
        .then(data => {
          if (data) {
            setMessageSuccess(`¡Hola, ${data?.nombreFormateado}! continue en módulo.`);
            postDataToServer(`${data?.nombreFormateado}`);
            setData(data);
            setShowMessage(true);
            setOpenSuccessDialog(true);
            setDNI('');
            setTimeout(() => {
              setOpenSuccessDialog(false)
            }, 5000);
          } else {
            setMessageSuccess(`Listo! lo llamaremos en módulo como: VISITANTE ${dni}`)
            postDataToServer(`VISTANTE ${dni}`)
            setData({ nombre: `VISITANTE ${dni}` })
            setOpenSuccessDialog(true);

          }
          props.handleLoading(false)
        })
        .catch(error => {
          setMessageSuccess(`Listo! lo llamaremos en módulo como: VISITANTE ${dni}`)
          postDataToServer(`VISITANTE ${dni}`)
          setOpenSuccessDialog(true);
          setData({ nombre: `VISITANTE ${dni}` })
          props.handleLoading(false)
        });
    }
  }

  const handleYesDisability = () => {
    fetchRegister();
    setOpenDisabilityDialog(false);
    setHasDisability(true);
    localhasDisability = true;
    setOpenSuccessDialog(true);
  };

  const handleNoDisability = () => {
    fetchRegister();
    setOpenDisabilityDialog(false);
    setHasDisability(false);
    setOpenSuccessDialog(true);
  };

  const handleSuccessDialogClose = () => {
    setData(null)
    setOpenSuccessDialog(false); // Cerrar el SuccessDialog
    setShowMessage(false); // Ocultar el mensaje de éxito
    setDNI(''); // Limpiar el campo de DNI
    props.handleLoading(false)
  };

  const handleDataUpdate = (name) => {
    props.handleLoading(false)
    setMessage(`Pase a plataforma, ${name}`);
    setShowMessage(true);
    setOpenSuccessDialog(true);
    setTimeout(() => {
      setShowMessage(false);
      setOpenSuccessDialog(false);
      setDNI('');
    }, 7000);
  };

  return (
    <div className="root">
      <Container maxWidth="xxl" className="formContainer">
        <img src={logo} alt="DNI" style={{ width: '200px' }} />
        <div className='lineBanner' />
        <div className="titleContainer">
          <Typography variant="h4" gutterBottom className="titleBanner2">
            Bienvenido a
          </Typography>
          <Typography variant="h4" gutterBottom className="titleBanner">
            Clínica Arequipa
          </Typography>
        </div>
        <div className="containerData">

          {showLayoutHome ? (
            <>
              <div style={{ display: "flex", paddingBottom: "20px" }}>
                <TextField
                  keyboardType="numeric"
                  inputProps={{ inputMode: 'numeric' }}
                  className="formField"
                  variant="outlined"
                  value={dni}
                  autoFocus={true}
                  onChange={(e) => setDNI(e.target.value)}
                  sx={{
                    '.MuiInputBase-input': { fontSize: '2.55rem' },
                  }}
                />
                <Button
                  className="submitButton"
                  variant="contained"
                  color="primary"
                  onClick={handleRegister}
                >
                  Registrar
                </Button>
              </div>
              <Typography gutterBottom className="titleBanner3">
                Ingrese su DNI o Pasaporte
              </Typography>
              <img src={dniImage} alt="DNI" style={{ width: '400px' }} />

              <Button variant="outlined" color="primary" onClick={() => { setShowLayoutHome(false); }}>
                Regresar
              </Button>

            </>
          ) :
            <Button variant="contained" color="primary" onClick={handleSACARCITA} className="button-register" >
              <div style={{ marginTop: "-40px" }}>
                <Lottie animationData={iconAdmision} style={{ height: "200px" }} />
                <div className="labelButton" style={{ marginTop: "-20px" }}> Sacar Cita </div>
              </div>
            </Button>
          }

          <CreateDialog
            open={showCreateDialog}
            onClose={() => setShowCreateDialog(false)}
            fullWidth
            onRegister={handleDataUpdate}
          />

          <DisabilityDialog
            open={openDisabilityDialog}
            onClose={() => setOpenDisabilityDialog(false)}
            onYes={handleYesDisability}
            onNo={handleNoDisability}
          />
          {data &&
            <SuccessDialog
              open={openSuccessDialog}
              onClose={handleSuccessDialogClose}
              message={messageSuccess}
            />
          }
        </div>
      </Container>
    </div>
  );
}

export default Register;

