// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  width: 100%;
  height: 30%;
  background-color: #FF5733;
  border-bottom: 1px solid #ccc;
  text-align: center;
  font-size: 30px;
  text-wrap: wrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  height: 100%;
}

.module-selector {
  width: 100%;
  background-color: #FFC300; /* Color amarillo llamativo */
  font-size: 40px;
  text-align: center;
}

.next-button {
  width: 100%;
  font-size: xx-large;
  background-color: #4ce930; /* Color naranja llamativo */
}

@media screen and (max-width: 480px) {
  .module-selector {
    width: 100%;
    font-size: 30px;
  }

  .next-button {
    width: 100%;
    font-size: 4rem;
  }
}
.next-button:hover {
  background-color: #0056b3; /* Change the background color on hover */
}
`, "",{"version":3,"sources":["webpack://./src/modules/tvs/style.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,YAAY;AACd;;AAEA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,WAAW;EACX,yBAAyB;EACzB,6BAA6B;EAC7B,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,YAAY;AACd;;AAEA;EACE,WAAW;EACX,yBAAyB,EAAE,6BAA6B;EACxD,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,yBAAyB,EAAE,4BAA4B;AACzD;;AAEA;EACE;IACE,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,eAAe;EACjB;AACF;AACA;EACE,yBAAyB,EAAE,yCAAyC;AACtE","sourcesContent":["body, html {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n}\n\n.container {\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.title {\n  width: 100%;\n  height: 30%;\n  background-color: #FF5733;\n  border-bottom: 1px solid #ccc;\n  text-align: center;\n  font-size: 30px;\n  text-wrap: wrap;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.buttons-container {\n  display: flex;\n  justify-content: space-between;\n  padding: 10px;\n  height: 100%;\n}\n\n.module-selector {\n  width: 100%;\n  background-color: #FFC300; /* Color amarillo llamativo */\n  font-size: 40px;\n  text-align: center;\n}\n\n.next-button {\n  width: 100%;\n  font-size: xx-large;\n  background-color: #4ce930; /* Color naranja llamativo */\n}\n\n@media screen and (max-width: 480px) {\n  .module-selector {\n    width: 100%;\n    font-size: 30px;\n  }\n\n  .next-button {\n    width: 100%;\n    font-size: 4rem;\n  }\n}\n.next-button:hover {\n  background-color: #0056b3; /* Change the background color on hover */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
