import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import './register.css';

import preferencial from './preferencial.png';

function DisabilityDialog({ open, onClose, onYes, onNo }) {
  return (
    <Dialog open={open} onClose={onClose} >
      <DialogTitle >
        <div style={{display: "flex",
      justifyContent: "center"}}>
          <img src={preferencial} alt="DNI" style={{ width: '260px'}} />
        </div>
        </DialogTitle>
      <DialogContent>
      <Typography gutterBottom className="titleBanner4" sx={{padding: "18px",  textAlign: "center"}}>
          ¿Requiere atención preferencial?
          </Typography>
            
      </DialogContent>
      <DialogActions>
        <div style={{display:"flex", justifyContent:"center", width: "100%", gap: "20px", paddingBottom:"20px"}}>
        <Button onClick={onYes} className='submitButton' sx={{color: "#FFFF", height:"90px !important", fontSize:"35px !important"}}>
          Sí
        </Button>
        <Button onClick={onNo} className='submitButton' sx={{color: "#FFFF", height:"90px !important", fontSize:"35px !important"}}>
          No
        </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default DisabilityDialog;
