import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Register from './modules/register/Register';
import "@fontsource/urbanist";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Screen from './modules/screen/Screen';
import Tvs from './modules/tvs/tvs';


function App() {
  const [open, setOpen] = React.useState(false);
  const handleLoading = (value) => {
    setOpen(value);
  };

  return (
    <Router>
      <Routes>
        <Route path="/register" element={<Register handleLoading={handleLoading} />} />
        <Route path="/screen" element={<Screen handleLoading={handleLoading} />} />
        <Route path="/buttons" element={<Tvs handleLoading={handleLoading} />} />

      </Routes>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Router>

  );
}

export default App;
