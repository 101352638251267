import React, { useState, useEffect, useRef } from 'react';
import './styles.css';
import logo from './logo-clinica-arequipa-50.png';
import { socket } from '../../config/socket';
import bepp from "./bepp.mp3";
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';


function Screen() {
  const [pendingQueue, setPendingQueue] = useState([]);
  const [attendingQueue, setAttendingQueue] = useState([]);
  const [readerActive, setReaderActive] = useState(false);
  const [videoActive, setVideoActive] = useState(false);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [fooEvents, setFooEvents] = useState([]);
  const audioRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const speechRef = useRef(null);
  const [volumeVideo, setVolumeVideo] = useState(0)
  const [muted, setMuted] = useState(true)
  const [open, setOpen] = useState(false);
  const [soundEnabled, setSoundEnabled] = useState(false);

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videoRef = useRef(null);
  const videos = [
    'https://cdn.jsdelivr.net/gh/pbrodriguezm/comercialesTV@main/video0.mp4',
    'https://cdn.jsdelivr.net/gh/pbrodriguezm/comercialesTV@main/video1_f9ZaoCPl.mp4',
    'https://cdn.jsdelivr.net/gh/pbrodriguezm/comercialesTV@main/video2.mp4',
    'https://cdn.jsdelivr.net/gh/pbrodriguezm/comercialesTV@main/video3.mp4',
    'https://cdn.jsdelivr.net/gh/pbrodriguezm/comercialesTV@main/video7.mp4',

  ];

  useEffect(() => {
    const handleVideoEnd = () => {
      setCurrentVideoIndex(prevIndex => (prevIndex + 1) % videos.length);
    };

    // Attach event listener to the video element
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('ended', handleVideoEnd);
    }

    setOpen(true);
    // Remove event listener when component unmounts
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('ended', handleVideoEnd);
      }
    };

  }, [videos.length]);

  // Reiniciar el ciclo de videos al finalizar el último video
  useEffect(() => {
    if (currentVideoIndex === videos.length) {
      setCurrentVideoIndex(0);
    }
  }, [currentVideoIndex, videos.length]);

  const handleSoundClick = () => {
    setSoundEnabled(true)
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const audio = new Audio(bepp);
  audio.loop = true;
  // const [play] = useSound(bepp);

  useEffect(() => {

    fetchPending();
    fetchAttending();
    // toggleReader();
  }, []);
  useEffect(() => {
    function onConnect() {
      console.log('Conexion a Socket lista');
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('pendingUpdate', fetchPending);
    socket.on('attendingUpdate', updateAttending);

    return () => {
      socket.off('pendingUpdate', fetchPending);
      socket.off('attendingUpdate', fetchAttending);
    };
  }, []);

  const toggleReader = () => {
    setShowModal(true); // Mostrar el modal cuando se activa el lector
  };


  const updateAttending = async () => {
    fetchPending();
    fetchAttending();
    audio.volume = 1;
    setTimeout(() => {
      reduceVolume();
    }, 2000);
  };

  const handleButtonClick = () => {
    speak("Clinica Arequipa");
    setVolumeVideo(0.6);
    setMuted(false);
    audio.play();
    socket.on('attendingUpdate', maxVolume);
    maxVolume();
    setReaderActive(!readerActive);
    setVideoActive(videoActive);

  };

  const reduceVolume = () => {
    audio.volume = 0;
  };

  const maxVolume = () => {
    audio.volume = 1;
    audio.currentTime = 0;
    setTimeout(() => {
      audio.volume = 0;
    }, 2000);
  };


  const fetchPending = async () => {
    try {
      const response = await fetch('https://tvs-api.chatup.pe/api/colas/pending');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setPendingQueue(data);
    } catch (error) {
      console.error('Error fetching pending queue:', error);
    }
  };

  const fetchAttending = async () => {
    try {
      const response = await fetch('https://tvs-api.chatup.pe/api/colas/attending');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      // Ordenar la lista por startTime de forma descendente
      data.sort((a, b) => new Date(b.startTime) - new Date(a.startTime));
      setAttendingQueue(data);
    } catch (error) {
      console.error('Error fetching attending queue:', error);
    }
  };


  const speak = (text) => {
    if ('speechSynthesis' in window) {
      const speechSynthesis = window.speechSynthesis;
      const voices = speechSynthesis.getVoices();
      if (voices.length) {
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.rate = 0.6;
        utterance.pitch = 0.5;
        const monicaVoice = voices.find(voice => voice.lang === 'es-ES');
        if (monicaVoice) {
          utterance.voice = monicaVoice;
        }
        speechSynthesis.speak(utterance);
      } else {
        console.log('No hay voces disponibles');
      }
    } else {
      console.log('El navegador no soporta la API de síntesis de voz');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && open) {
      handleSoundClick();
    }
  };

  return (
    <div>
      {/* Call Section */}
      <div className="call-section">
        {attendingQueue.slice(0, 4).map((person, index) => (
          <div key={index} className={`call-box ${index === 0 ? 'highlight' : ''}`}>
            <div className='title-card'>{person.module}</div>
            <div className="call-item">{person.name}</div>
          </div>
        ))}
      </div>

      <div className="queue-screen">
        <audio ref={audioRef} src="bepp.mp3" />
        <section className="container-Left">
          {/* Video Section */}
          <div className="video-section" onKeyDown={handleKeyDown} tabIndex={0}>
            <video ref={videoRef} src={videos[currentVideoIndex]} autoPlay muted={!soundEnabled} />
            <Dialog open={open} onClose={handleClose} fullWidth>
              <DialogTitle>Activar sonido</DialogTitle>
              <DialogContent>
                <Button sx={{ width: '100%' }} variant='contained' size='large' color='primary' onClick={handleSoundClick} autoFocus>Activar</Button>
              </DialogContent>
            </Dialog>
          </div>

        </section >
        {/* Waiting Section */}
        < section className="waiting-section" >
          {!readerActive &&
            <button onClick={handleButtonClick} autoFocus width="100%">
              {readerActive ? 'Sonando' : 'Activar Sonido'}
            </button>
          }
          <div className="title-card">Cola de espera</div>
          {
            pendingQueue.map((person, index) => (
              <div key={index} className="queue-item">

                {person.isPreferred ? <span>&#9855;</span> : index + 1} {' '}
                {person.name}
              </div>
            ))
          }
        </section >
      </div >
    </div>
  );
}

export default Screen;
